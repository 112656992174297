import React, { useState } from "react";
import Header from "../../header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Tab from "../../tab/Tab";
import DropDown from "../../global/fields/DropDown";
import Toggle from "../../global/Toggle";
import UpgradeVariant from "./UpgradeVariant";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
import { useDispatch, useSelector } from "react-redux";
import ToggleSchedule from "../../home/slideshow/ToggleSchedule";
import DropdownDuration from "../../home/blogs/Dropdown";
import ToggleYesNo from "../../global/fields/ToggleYesNo";
import CalendarModalTrigger from "../../home/blogs/calendar/CalendarModalTrigger";
// import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";
import { useEffect } from "react";
import {
  selectEditVariantTab,
  setEditVariantTab,
} from "../../../redux/features/courseware/courseSlice";

const EditVariant = () => {
  const tabContent = [
    {
      title: "Variant Details",
      content: <VariantDetails />,
    },
    {
      title: "Upgrade Variants",
      content: <UpgradeVariant />,
    },
  ];

  const activeTab = useSelector(selectEditVariantTab);
  const dispatch = useDispatch();

  function handleTabChange(tab) {
    dispatch(setEditVariantTab(tab));
  }

  return (
    <div>
      <Header />
      <div className="mt-6 ml-2">
        <BreadCrumb type={"editCourse"} />
      </div>
      <section className="mt-5 w-full">
        <Tab
          className="ml-11"
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        >
          {tabContent.map((item, i) => (
            <Tab.TabPane key={`tab-${i}`} tab={item.title}>
              {item.content}
            </Tab.TabPane>
          ))}
        </Tab>
      </section>
    </div>
  );
};

const VariantDetails = () => {
  const variantObj = useSelector((state) => state?.variantData?.variantObj);

  let history = useHistory();
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const setTime =
    variantObj.startTime !== "None" ? variantObj.startTime.split(":") : "";
  const [variantName, setVariantName] = useState(variantObj.variantName);
  const [limaCode, setLimaCode] = useState(variantObj.limaCode);
  const [selectedCourse, setSelectCourse] = useState(variantObj.courseNames);
  const [activeTabForPublish, setActiveTabforPublish] = useState(
    variantObj?.publishStatus === "Published" ? "now" : "schedule"
  );
  const [variantStatus, setVariantStatus] = useState(
    variantObj.isActive === "1" ? "active" : "0" && "inactive"
  );
  const [isSession, setIsSession] = useState(
    variantObj?.meetaMentorSlotsAvailable === "None" ? false : true
  );
  const [startdate, setStartDate] = useState(variantObj.startDate);
  const [enddate, setEndDate] = useState(variantObj.endDate);
  const [myPlanSessions, setMyplanSessions] = useState(
    variantObj.meetaMentorSlotsAvailable
  );
  const [isCentreProctoredAllowed, setIsCentreProctoredAllowed] = useState(
    variantObj?.centreProctoredAllowed === "1" ? true : false
  );
  const [isVisibleInProgram, setIsVisibleInProgram] = useState(
    variantObj?.visible_inchange_program === "1" ? true : false
  );

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [hour, setHour] = useState(
    setTime != ""
      ? Array.from(String(setTime[0]), Number)[0] === 0
        ? Array.from(String(setTime[0]), Number)[1]
        : setTime[0]
      : "00"
  );
  const [minutes, setMinutes] = useState(
    setTime != ""
      ? Array.from(String(setTime[1]), Number)[0] === 0
        ? Array.from(String(setTime[1]), Number)[1]
        : setTime[1]
      : "00"
  );
  const [seconds, setSeconds] = useState(
    setTime != ""
      ? Array.from(String(setTime[2]), Number)[0] === 0
        ? Array.from(String(setTime[2]), Number)[1]
        : setTime[2]
      : "00"
  );
  const [keyCode, setKeyCode] = useState("");
  const limaCodeRegEx = /^[0-9]*$/;
  const onlyZero = /^[0]+$/;
  const regex = /.*[a-zA-Z]+.*/gm;
  let isActive = variantStatus === "active" ? "1" : "inactive" && "0";

  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method: "post",
      data: {
        adminimspin: login_Details.userImsPin,
      },
    })
  );
  // query for edit variant ===============>
  const queryClient = useQueryClient();
  const { refetch } = useQuery(
    endpoint.edit_variant_data.key,
    () =>
      apirequest({
        url: `${endpoint.edit_variant_data.addr}`,
        method: "put",
        data: {
          variantId: variantObj.variantId,
          variantName: variantName,
          limaCode: limaCode,
          isActive: isActive,
          modifiedBy: login_Details.userImsPin,
          meetamentorSlots: myPlanSessions,
          startDate: activeTabForPublish == "now" ? "" : startdate,
          startTime:
            activeTabForPublish == "now" ? "" : `${hour}:${minutes}:${seconds}`,
          endDate: enddate,
          courseName: selectedCourse,
          requestType: "update",
          centreProctoredAllowed: isCentreProctoredAllowed ? "1" : "0",
          visible_inchange_program: isVisibleInProgram ? "1" : "0",
        },
      }),
    {
      enabled: false,
      onMutate: (id) => {
        queryClient.setQueryData(
          [endpoint.variant_get_data.key, id.variantId],
          (val) => [val, id]
        );
        queryClient.refetchQueries(endpoint.variant_get_data.key, () =>
          apirequest({
            url: `${endpoint.variant_get_data.addr}`,
          })
        );
      },
      onSuccess: (res) => alert(res.data.message),
      onError: (err) => alert(err.data.message),
      retry: 1,
    }
  );

  // Query for adding the variant  =============>
  const handleSubmit = () => {
    // if((variantName !="" && limaCode !="" &&  enddate != "" && selectedCourse != "" )){
    //   if(activeTabForPublish ==="schedule" ){ `${hour}:${minutes}:${seconds}`!= "00:00:00" ? refetch() :(setShowErrorMessage(true)); }
    //   else if(activeTabForPublish ==="now")(refetch() );
    // }else{
    //   setShowErrorMessage(true);
    // }
    console.log(setShowErrorMessage);
    if (!variantName.match(regex)) {
      alert("Please enter a Valid Variant Name");
    } else if (!limaCode.match(limaCodeRegEx) || limaCode.match(onlyZero)) {
      alert("Please Enter a Valid Lima Code");
    } else if (
      Number(startdate.replace(/-/g, "")) > Number(enddate.replace(/-/g, ""))
    ) {
      alert("please select End Date after Start Date");
    } else if (isSession) {
      if (myPlanSessions === "") {
        alert("Enter Session Count !!!");
      } else {
        refetch();
      }
    } else refetch();
  };
  const handleKeyDown = (e) => {
    setKeyCode(e.keyCode);
  };
  const handleMyPlansession = (e) => {
    setMyplanSessions(e.target.value);
    var regex = /^[-+]?[0-9]+$/;
    setMyplanSessions(() => {
      if (e.target.value.match(regex) || keyCode === 8) {
        return e.target.value;
      } else {
        return myPlanSessions;
      }
    });
  };
  useEffect(() => {
    !isSession && setMyplanSessions("");
  }, [isSession]);
  return (
    <div className="m-7">
      <div className="font-ibm flex justify-center mt-7 ">
        <form className="space-y-5 w-[93%] shadow-outer rounded-2xl p-10">
          <div className="space-x-16">
            <label className="font-normal text-lg">Variant Name</label>
            <input
              placeholder="Enter Variant name"
              value={variantName}
              onChange={(e) => setVariantName(e.target.value)}
              className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
            {showErrorMessage && (
              <span className="text-red-600"> All Fields are Mandatory *</span>
            )}
          </div>
          <div className="space-x-[90px]">
            <label className="font-normal text-lg">Lima Code</label>
            <input
              placeholder="Enter Lima code"
              value={limaCode}
              onChange={(e) => setLimaCode(e.target.value)}
              className="bg-white border-2 w-[31%] border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          <div className="flex space-x-[90px]">
            <label className="font-normal text-lg mt-2">Courses</label>
            <DropDown
              valueReadCourseTag={selectedCourse}
              type="multiselect"
              options={getCourseData?.data?.data?.courseList}
              placeholder="Select Course"
              width={"w-[22rem]"}
              forCourses={true}
              setUpdatedOptions={setSelectCourse}
            />
          </div>
          <div className="flex space-x-8">
            <ToggleSchedule
              title="Publish"
              activeTab={activeTabForPublish}
              setActiveTab={setActiveTabforPublish}
              margin="28"
            />
            {activeTabForPublish === "schedule" && (
              <div className="flex  space-x-5">
                <label className="font-normal text-lg mt-2">Start Date</label>
                <aside className="flex">
                  <input
                    placeholder="select Start Date"
                    readOnly
                    value={startdate}
                    className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                  />
                  <div className="relative top-4 right-8">
                    <CalendarModalTrigger setDate={setStartDate} />
                  </div>
                </aside>
              </div>
            )}
          </div>
          {activeTabForPublish === "schedule" && (
            <div className="flex">
              <label className=" font-normal text-lg font-ibm -mr-4">
                Start time
              </label>
              <div className="ml-28">
                <DropdownDuration
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  setSelectedValue={setHour}
                  selectedValue={hour}
                />
              </div>
              <div className="ml-2">
                <DropdownDuration
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  setSelectedValue={setMinutes}
                  selectedValue={minutes}
                />
              </div>
              <div className="ml-2">
                <DropdownDuration
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  setSelectedValue={setSeconds}
                  selectedValue={seconds}
                />
              </div>
            </div>
          )}
          <div className="flex  space-x-[100px]">
            <label className="font-normal text-lg mt-2">End Date</label>
            <div className="flex">
              <input
                placeholder="select End Date"
                value={enddate}
                readOnly
                className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
              />
              <div className="relative top-4 right-8">
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
          </div>
          <Toggle
            margin="16"
            title="This Variant is"
            activeTab={variantStatus}
            setActiveTab={setVariantStatus}
          />
          <ToggleYesNo
            title="Centre Proctored Allowed"
            toggleState={isCentreProctoredAllowed}
            setToggleState={setIsCentreProctoredAllowed}
          />
          <ToggleYesNo
            title="Visible in Change Program"
            toggleState={isVisibleInProgram}
            setToggleState={setIsVisibleInProgram}
          />
          <div className="flex space-x-10">
            <ToggleYesNo
              title="myPlan Sessions"
              mg="mr-[40px]"
              toggleState={isSession}
              setToggleState={setIsSession}
            />
            {isSession && (
              <input
                onKeyDown={handleKeyDown}
                name="myPlanSessions"
                value={myPlanSessions}
                placeholder="Set your sessions here"
                onChange={(e) => handleMyPlansession(e)}
                className="bg-white border-2 w-[25%] border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              />
            )}
          </div>
        </form>
      </div>
      <div className="flex justify-center mt-5 space-x-5">
        <button
          className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
          onClick={handleSubmit}
        >
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={history.goBack}
        >
          Exit
        </button>
      </div>
    </div>
  );
};
export default EditVariant;
